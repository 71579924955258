import { useStaticQuery, graphql } from "gatsby"

export const useExamGroupsQuery = () => {
  const data = useStaticQuery(query)
  return data.allStrapiExamgroups.nodes
}

export const query = graphql`
  {
    allStrapiExamgroups(sort: { fields: id, order: ASC }) {
      nodes {
        id
        name
        isActive
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, height: 50)
            }
          }
        }
        exams {
          examId
          examName
          examFullName
          examSlug
          isActive
          profession {
            organisation
            sector
          }
          icon {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, height: 50)
              }
            }
          }
        }
        subscription_plans {
          id
          isActive
          displayName
          basePrice
          planFeatures
          durations {
            id
            isActive
            durationMonths
            price
            basePrice
          }
        }
      }
    }
  }
`
