import axios from "axios"
import store from "store"

const api = axios.create({
  baseURL: process.env.GATSBY_API_URL,
})

api.interceptors.request.use(function (config) {
  const token = store.get("stoken")
  config.headers.Authorization = token ? `Bearer ${token}` : ""
  return config
})

export const getStudent = async (currentUserEmail, config) => {
  const res = await api.get(`/students?_email=${currentUserEmail}`, config)
  return res.data
}

export const getSubscriptions = async (currentUserEmail, config) => {
  const res = await api.get(`/subscriptions?_email=${currentUserEmail}`, config)
  return res.data
}

export const getSubscriptionPlans = async config => {
  const res = await api.get(`/subscription-plans`, config)
  return res.data
}

export const getExams = async config => {
  const res = await api.get(`/exams`, config)
  return res.data
}

export const getTemplate = async (id, config) => {
  const res = await api.get(`/templates/${id}`, config)
  return res.data
}

export const postResponse = async (payload, config) => {
  const res = await api.post(`/attempts/`, payload, config)
  return res
}
export const postStudent = async (payload, config) => {
  const res = await api.post(`/students/`, payload, config)
  return res
}
export const postFeedback = async (payload, config) => {
  const res = await api.post(`/feedbacks/`, payload, config)
  return res
}
