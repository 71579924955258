import React from "react"
import { useQueryClient, useMutation } from "react-query"
import { updateEnrollments } from "../../context/graph"
import { useQuery } from "react-query"
import * as api from "../../context/api"
import { useAuth } from "../../context/auth"
import { MdOutlineRemoveCircle, MdAddCircle } from "react-icons/md"
import { IoIosCheckmarkCircle, IoIosAddCircle } from "react-icons/io"

export const EnrollButton = ({ examId, isBiggest = false }) => {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()

  const {
    data: student,
    isLoading,
    isError,
  } = useQuery(
    ["getStudent", currentUser?.email],
    () => api.getStudent(currentUser?.email),
    {
      enabled: !!currentUser,
    }
  )

  //   if (isLoading) return <p>Loading...</p>
  //   if (isError) return <p>Something went wrong</p>

  const enrollments =
    student && student[0]?.enrollments?.split(",").filter(e => e)

  const mutation = useMutation(updateEnrollments, {
    onSuccess: data => queryClient.invalidateQueries("getStudent"),
  })

  function isAlreadyEnrolled(examId) {
    return enrollments?.find(id => id.trim() === examId)
  }

  function enrollmentHandler(examId) {
    let isEnrolled = isAlreadyEnrolled(examId)
    let updatedEnrollments = []
    if (isEnrolled) {
      updatedEnrollments = enrollments?.filter(id => id.trim() !== examId)
    } else {
      updatedEnrollments = [...enrollments, examId]
    }
    const data = {
      examId: updatedEnrollments?.filter(e => e).toString(),
      id: student[0]?.id,
    }
    mutation.mutate(data)
  }

  if (isBiggest)
    return (
      <button
        onClick={() => {
          enrollmentHandler(examId)
        }}
        className={`flex items-center w-full rounded-xl shadow p-4 ${
          isAlreadyEnrolled(examId)
            ? "bg-red-50 text-red-500"
            : "text-blue-600 bg-blue-50"
        }`}
      >
        {isAlreadyEnrolled(examId) ? (
          <MdOutlineRemoveCircle className="mr-2 text-red" />
        ) : (
          <MdAddCircle className="mr-2 text-blue" />
        )}

        {isAlreadyEnrolled(examId) ? "Remove Enrollment" : "Enroll"}
      </button>
    )

  return (
    <button
      onClick={() => {
        enrollmentHandler(examId)
      }}
      className={`h-6 w-16 border focus:outline-none  text-xs  rounded ${
        isAlreadyEnrolled(examId)
          ? "bg-green-50 text-green-500 border-none"
          : "text-gray-600 border-gray-200"
      }`}
    >
      {isAlreadyEnrolled(examId) ? "Enrolled" : "Enroll"}
    </button>
  )
}
