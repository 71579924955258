import React from "react"
import _ from "lodash"
import { Link } from "gatsby"
import { MdKeyboardArrowRight } from "react-icons/md"
import { GatsbyImage } from "gatsby-plugin-image"
import { useExamGroupsQuery } from "../../../components/context/static-queries/useExamGroupsQuery"

const ExamGroupsCard = ({ examGroupNames = [], examId = "" }) => {
  const examGroups = useExamGroupsQuery()
  let filterExamGroups = examGroups

  if (examGroupNames.length) {
    filterExamGroups = _.filter(filterExamGroups, exam => {
      return examGroupNames.some(examgroup => examgroup === exam.name)
    })
  }

  if (examId.length) {
    filterExamGroups = _.filter(filterExamGroups, exam => {
      return exam.exams.some(examlist => examlist.examId === examId)
    })
  }

  return (
    <div className="space-y-4">
      {filterExamGroups.map(exam => (
        <ListComponent
          key={exam.id}
          title={exam.name}
          examGroup={exam.name}
          url="/app/exams"
          exam={exam}
          icon={exam.icon.localFile.childImageSharp.gatsbyImageData}
        />
      ))}
    </div>
  )
}

function ListComponent({ title, examGroup, icon, url, exam }) {
  return (
    <Link
      to={url}
      href={url}
      state={{
        examGroup: examGroup,
        url: url,
      }}
      className={`flex p-4 relative bg-white shadow rounded-xl justify-between items-center  `}
    >
      <div className="flex w-full items-center justify-start ">
        <div className="  w-24 object-contain flex flex-col justify-center items-center pr-6 ">
          {icon && <GatsbyImage image={icon} alt={title} />}
        </div>
        <div className="bg-red-200 bg-opacity-50 hidden absolute left-6 bottom-0 z-30 px-2 rounded-t-md  text-xs text-red-500">
          50% off
        </div>
        <div className="flex flex-col ">
          <p className="font-base text-xl ">{title}</p>
          <p className="font-base text-md text-gray-500">
            {exam.exams?.length}+ exams
          </p>
          <div className="flex justify-center items-center bg-red-200 bg-opacity-50 px-2 rounded w-16 text-xs text-red-500">
            10% off
          </div>
        </div>
      </div>
      <MdKeyboardArrowRight className="w-6 h-6" />
    </Link>
  )
}

export default ExamGroupsCard
