import { useStaticQuery, graphql } from "gatsby"

export const useExamsQuery = () => {
  const data = useStaticQuery(query)
  return data.allStrapiExams.nodes
}

export const query = graphql`
  {
    allStrapiExams(
      filter: { isActive: { eq: true } }
      sort: { fields: examData___totalApplicants, order: DESC }
    ) {
      nodes {
        id
        examId
        examSlug
        examName
        examFullName
        examGroup {
          group
          id
        }
        examgroups {
          id
          name
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
        structure {
          optionalExams
          optionalMaxCount
          phase
          papers {
            paperId
            paperName
            displayName
            slug
          }
        }
        exam_tags {
          displayName
          tagId
        }
        jobTitles
        profession {
          organisation
          sector
        }
        examData {
          salary
          vacancy
          totalApplicants
          careerOutlook
        }
        icon {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(width: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
