import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useExamsQuery } from "../../context/static-queries/useExamsQuery"
import { EnrollButton } from "./EnrollButton"
import _ from "lodash"

const PopularExams = ({ limit = 4, group = "" }) => {
  const exams = useExamsQuery()
  let popularExams = exams

  if (group.length > 1) {
    popularExams = _.filter(popularExams, exam => {
      return exam.examgroups.some(item => item.name === group)
    })
  }

  // if (!popularExams.length) {
  //   popularExams = exams
  // }

  popularExams = popularExams.slice(0, limit)

  return (
    <div className="grid grid-cols-4 gap-2">
      {popularExams.map(exam => (
        <div
          key={exam.id}
          className="flex flex-col items-center justify-between px-2 py-4 bg-white rounded-md"
        >
          <Link
            to={`/app/exams/${exam.examSlug}`}
            className="flex flex-col items-center justify-start"
          >
            <div className="w-12 h-12 mb-2 overflow-hidden">
              <GatsbyImage
                image={exam.icon?.localFile.childImageSharp.gatsbyImageData}
                alt={exam.examId}
              />
            </div>
            <p className="mb-2 text-xs text-center">{exam.examName}</p>
          </Link>
          <EnrollButton examId={exam.examId} />
        </div>
      ))}
    </div>
  )
}

export default PopularExams
