import React from "react"
import { Link } from "gatsby"
import AppLayout from "../../components/app/app-components/layout"
import PopularExams from "../../components/app/app-components/PopularExams"
import ExamGroupsCard from "../../components/app/app-components/ExamGroupsCard"
import { motion } from "framer-motion"
import { myAnimations } from "../../components/context/framer-animations"
import MyExamsContainer from "../../components/app/containers/MyExamsContainer"

const ExamList = ({ path }) => {
  return (
    <AppLayout isBackButton={true} title="Exams" path={path}>
      <motion.div {...myAnimations.fadeIn} className="p-4 pb-24 space-y-10">
        <div>
          <MyExamsContainer />
        </div>
        <div className="flex flex-col space-y-2 ">
          <TitleWithViewAll title="Popular Exams" uri="/app/popular-all" />
          <PopularExams limit={8} />
        </div>
        <div className="space-y-4">
          <div className="flex justify-between mt-6 mb-4">
            <p className="font-base">Explore</p>
            <Link
              to="/app/exams"
              className="px-3 text-sm align-middle bg-gray-200 rounded-full"
            >
              view all
            </Link>
          </div>
          <ExamGroupsCard />
        </div>
      </motion.div>
    </AppLayout>
  )
}

function TitleWithViewAll({ title, uri }) {
  return (
    <div className="flex justify-between ">
      <p className="font-base">{title}</p>
      <Link
        to={uri}
        className="px-3 text-sm align-middle bg-gray-200 rounded-full"
      >
        view all
      </Link>
    </div>
  )
}

export default ExamList
